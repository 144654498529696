import bibliotheque from './../assets/images/bibliotheque.jpeg';
import occupationFoyer from './../assets/documents/general/OCCUPATION-SALLES-COMMUNALES-Foyer.pdf';
import reglementFoyer from './../assets/documents/general/Reglement-foyer.pdf';
import reservationFoyer from './../assets/documents/general/reservation-salle-municipale.pdf';
import plateauMultiSports from './../assets/images/plateau_multi_sports.jpeg';
import pingPong from './../assets/images/ping-pong.jpeg';
import stade from './../assets/images/stade.JPG.webp';
import fitness from './../assets/images/fitness.jpeg';
import parcoursSante from './../assets/images/parcours_sante.jpeg';
import aireDeJeux from './../assets/images/aire_de_jeux.jpeg';
import transportScolaire1 from './../assets/documents/general/transport-scolaire-1.png';
import transportScolaire2 from './../assets/documents/general/transport-scolaire-2.png';
import caveCooperative from './../assets/images/cave_cooperative.jpg';
import mairieEcoles from './../assets/images/mairie_ecoles.jpg';
import douchesPubliques from './../assets/images/douches_publiques.jpg';
import temple from './../assets/images/temple.jpg';
import ecoleDesMas from './../assets/images/ecole_des_mas.jpg';
import anciennePoste from './../assets/images/ancienne_poste.jpg';
import chateau from './../assets/images/chateau.jpg';
import histoireLogo from './../assets/images/histoire_logo.jpeg';
import histoireLogo2 from './../assets/images/histoire_logo_2.jpeg';
import laBastide from './../assets/images/la_bastide.jpeg';
import lesHirondelles from './../assets/images/les_hirondelles.jpeg';
import masCevenol from './../assets/images/mas_cevenol.jpeg';
import masJulian from './../assets/images/mas_julian.jpeg';
import orangerie from './../assets/images/orangerie.jpeg';
import fontGranada from './../assets/images/font_granada.png';
import beauRivage from './../assets/images/beaurivage.jpeg';
import chercheurOr from './../assets/images/chercheur_d_or.jpeg';


const pages = [
    {
        id: 1,
        path: '/vie-quotidienne/la-mairie-les-elus/les-elus-commissions',
        name: 'Les élus & commissions',
        content: '',
    },
    {
        id: 2,
        path: '/vie-quotidienne/la-mairie-les-elus/comptes-rendus-du-conseil-municipal',
        name: 'Comptes rendus du conseil municipal',
        content: '',
    },
    {
        id: 3,
        path: '/vie-quotidienne/la-mairie-les-elus/appels-doffres',
        name: 'Appels d\'offres',
        content: 'Aucun appel d\'offres en cours',
    },
    {
        id: 4,
        path: '/vie-quotidienne/infrastructures-communales/la-mairie-agence-postale',
        name: 'La mairie & agence postale',
        content: '',
    },
    {
        id: 5,
        path: '/vie-quotidienne/infrastructures-communales/la-bibliotheque',
        name: 'La bibliothèque',
        content: `La bibliothèque communale vous accueille :

            - le mardi de 16h00 à 17h30
            - le mercredi de 16h30 à 18h00
        
        Grâce au réseau intercommunal de lecture publique et à une navette sillonnant le territoire, chacun peut emprunter et rendre des documents dans l’une des bibliothèques du réseau de son choix. 
        
        Il vous suffit de consulter en ligne le catalogue commun à l’ensemble du réseau de lecture publique en cliquant sur le lien ci-dessous.`,
        image: bibliotheque,
        link: 'https://piemontcevenol-pom.c3rb.org',
        link_name: 'Catalogue en ligne',
    },
    {
        id: 6,
        path: '/vie-quotidienne/infrastructures-communales/le-foyer-location',
        name: 'Le foyer - location',
        content: `Le foyer communal est composé d’une salle, de toilettes, un bar et une cuisine. Sa capacité est de 156 personnes. Lors de leur mise à disposition les locaux pourront être ouverts de 8H00 jusqu’à 2h00.

        Le tarif de location est de 200 €. Le montant du dépôt de garantie de 300 €. Une caution de 50 € pour le ménage est également demandée.
        
        Pour toute réservation & complément d’informations, rapprochez vous de la mairie, soit en vous rendant sur place, soit par téléphone au 04.66.83.81.42.`,
        links: [
            {
                id: 1,
                path: occupationFoyer,
                name: 'Occupation des salles communales',
            }, 
            {
                id: 2,
                path: reglementFoyer,
                name: 'Règlement intérieur',
            },
            {
                id: 3,
                path: reservationFoyer,
                name: 'Réservation salle municipale',
            },
        ],
    },
    {
        id: 7,
        path: '/vie-quotidienne/infrastructures-communales/equipements-sportifs-loisirs',
        name: 'Equipements sportifs & loisirs',
        content: [
            {
                id: 1,
                name: 'Le plateau multi-sports',
                content: 'Le plateau multi-sports se situe à côté du stade, non loin du foyer communal. Il est ouvert à tous les sportifs. On peut y jouer au tennis bien-sûr, mais il est également doté de paniers de basket.',
                image: plateauMultiSports,
            },
            {
                id: 2,
                name: 'La table de ping-pong',
                content: 'La table de ping-pong se situe à côté du stade, non loin du foyer communal. A vos raquettes !',
                image: pingPong,
            },
            {
                id: 3,
                name: 'Le stade',
                content: 'Ici se déroulent les entrainements de foot et d’athlétisme. Lieu de rassemblement pour les jeunes sportifs.',
                image: stade,
            },
            {
                id: 4,
                name: 'L\'atelier fitness',
                content: '6 éléments de fitness sont disponibles sur la commune. Ils se trouvent à côté du stade, en face de la mairie.',
                image: fitness,
            },
            {
                id: 5,
                name: 'Le parcours de santé',
                content: 'Composé d’une dizaine d’éléments en tous genres, il est situé sur le terrain municipal à l’entrée Ouest du village.',
                image: parcoursSante,
            },
            {
                id: 6,
                name: 'L\'aire de jeux',
                content: 'Un espace de loisirs pour les petits et grands enfants.',
                image: aireDeJeux,
            },
        ] 
    },
    {
        id: 8,
        path: `/vie-quotidienne/les-associations`,
        name: 'Les associations',
        content: '',
    },
    {
        id: 9,
        path: '/vie-quotidienne/commerces-entreprises-restaurants',
        name: 'Commerces, entreprises & restaurants',
        content: '',
    },
    {
        id: 10,
        path: '/vie-quotidienne/citoyennete/cafes-citoyens',
        name: 'Cafés citoyens',
        content: 'Depuis le 18 mars 2017, les réunions de quartiers ont été remplacées par le café citoyen. Le fond reste le même, c’est à dire la communication sereine entre habitants et élus, mais autour d’un bon café. Vous trouverez ici tous les documents relatifs à ces cafés citoyens (et les anciennes réunions de quartier).',
    },
    {
        id: 11,
        path: '/vie-quotidienne/citoyennete/bien-vivre-ensemble',
        name: 'Bien vivre ensemble',
        content: 'Voici les numéros du « Bien vivre ensemble » parus dans votre bulletin municipal, « Le Cardésien »',
    },
    {
        id: 12,
        path: '/vie-quotidienne/citoyennete/voisins-vigilants',
        name: 'Voisins vigilants',
        content: `Pourquoi devenir Voisin Vigilant et Solidaire ?
        La plateforme Voisins Vigilants et Solidaires est un site web communautaire permettant de mettre en relation les habitants d’un même quartier pour lutter ensemble contre le fléau des cambriolages de manière simple et gratuite.
         
        Réduisez efficacement l’insécurité
        En France, un cambriolage se produit toutes les 90 secondes. Le dispositif Voisins Vigilants et Solidaires est la réponse la plus efficace face à cette délinquance intolérable. Dans les quartiers de Voisins Vigilants, le ministère de l’Intérieur constate une baisse des cambriolages de -40% par an. Vous aussi, retrouvez la sérénité et réduisez efficacement la délinquance de votre quartier en adhérant au dispositif !
         
        Faites revivre l’entraide et la convivialité
        Vous pourrez, grâce à la plateforme de communication sécurisée des Voisins Vigilants et Solidaires, partager vos dates de départ en vacances, publier des annonces, faire connaissance avec vos voisins,… Vous allez créer un cadre de vie agréable où le partage, l’entraide et la solidarité sont les valeurs essentielles qui rassemblent le voisinage.
        
        Dissuadez les cambrioleurs
        Suite à votre inscription, vous aurez accès à la boutique en ligne « Voisins Vigilants et Solidaires » qui met à votre disposition autocollants et panneaux dissuasifs pour faire fuir d’éventuels cambrioleurs. Des centaines de communes soutiennent l’action des Voisins Vigilants en finançant cette signalétique et en devenant Mairie Vigilante et Solidaire.
         
        Informez et soyez informé de tout événement suspect
        Restez connecté à la vie de votre quartier avec un simple téléphone portable ! Pas besoin d’être expert en nouvelles technologies, le système d’alertes Voisins Vigilants a été conçu pour être accessible à tous. Performant et innovant, il informe instantanément chaque Voisin Vigilant dès qu’un danger potentiel est signalé par un voisin ou par la police municipale.
         
        Laissez-vous guider
        Notre équipe vous accompagne pas à pas pour vous aider à développer votre communauté. Un kit de démarrage (envoyé par courrier), un dossier de présentation, un manuel utilisateur… sont à votre disposition dans la boite à outils (accessible après inscription).
         
        Accédez à la boite à outils (manuel utilisateur, dossier de présentation, conseils et méthodologie …), à la boutique (commande de la signalétique), au système d’alertes gratuit par SMS, … en vous inscrivant gratuitement.
        
        <a href="https://www.voisinsvigilants.org/signup/registration" target="_blank">Inscrivez vous</a>`,   
    },
    {
        id: 13,
        path: '/vie-quotidienne/enfance-jeunesse/ecole',
        name: 'Ecole',
        content: `
        Ecole de Cardet
        École maternelle/primaire
        place de la mairie
        30350 CARDET
        
        Téléphone: 04 66 83 83 76
        
        Horaires de l’école :
        Lundi, mardi, jeudi et vendredi: 8h45-11h45/13h15-16h15
        
        Garderie:
        Horaires de la garderie: lundi, mardi, jeudi et vendredi de 7h30 à 8h30 et de 16h15 à 18h30`,
    },
    {
        id: 14,
        path: '/vie-quotidienne/enfance-jeunesse/transport-scolaire',
        name: 'Transport scolaire',
        content: '',
        image: transportScolaire1,
        image2: transportScolaire2,
    },
    {
        id: 15,
        path: '/vie-quotidienne/enfance-jeunesse/les-nounous',
        name: 'Les nounous',
        content: `

        Mme LUBRANO Nicole
        259 Chemin de la Voie Ferrée
        Tél : 06.18.21.00.54
        M@il : nicole.lubrano@hotmail.fr
        
        Mme FOURY Fanny
        493 Route de Montpellier
        Tél : 06.83.08.70.62
        M@il : fanny.foury@aliceadsl.fr
        
        Maison d'Assistantes Maternelles (MAM)
        Carole & Emmeline
        Ouverte depuis mars 2023 dans de tous nouveaux locaux.
        8 chemin des anciennes écoles.
        Tél : 07.68.56.80.45. ou 06.26.59.58.04.
        M@il : lesmamzelles.cardet@gmail.com
        
        `,
    },
    {
        id: 16,
        path: '/vie-quotidienne/gestion-des-dechets',
        name: 'Gestion des déchets',
        content: `        
        Les déchets sont gérés par la communauté de communes du Piémont Cévenol. Nous vous invitons à suivre ces liens pour tout savoir sur la gestion de vos déchets : `,
        links: [
            {
                id: 1,
                path: 'https://www.piemont-cevenol.fr/la-collecte-des-dechets-menagers/la-collecte-selective/',
                name: 'Le tri sélectif',
            },
            {
                id: 2,
                path: 'https://www.piemont-cevenol.fr/la-collecte-des-dechets-menagers/les-tournees-de-collecte/',
                name: 'La collecte des ordures ménagères',
            },
            {
                id: 3,
                path: 'https://www.piemont-cevenol.fr/la-collecte-des-dechets-menagers/les-decheteries/',
                name: 'La déchèterie',
            },
        ],
    },
    {
        id: 17,
        path: '/vie-quotidienne/numeros-utiles',
        name: 'Numéros utiles',
        content: `
        Général
        La Mairie & La Poste : 04 66 83 81 42
        L’école : 04 66 83 83 76
        La bibliothèque : 06 71 61 90 09
        La communauté des communes du Piémont Cévenol : 04 66 93 06 12
        Gendarmerie : 04 66 83 40 07
        La fourrière pour les animaux (SACPA) : 04 66 72 82 86
        Objets encombrants : 0800 540 540 (Numéro d’inscription). Ramassage GRATUIT sur demande
        Le Trésor Public : 04 66 77 31 37
        La CAF (Alès) : 08 10 25 30 10
        La CPAM (Alès) : 3646
        La préfecture : 04 66 36 43 90
        La sous-préfecture : 04 66 56 39 39
        Le Pôle Emploi : 3949
        Le centre médico-social : 04 66 56 49 50
        
        
        Urgences
        Pompiers : 18
        Samu : 15
        Police Secours : 17
        N° d’urgence européen : 112
        SOS enfants disparus : 116
        Enfance maltraitée : 119
        SOS femme violence conjugale : 3919
        Accueil sans abris : 115
        SOS médecins : 3624
        SOS gardes : 3915
        Contraception, IVG, MST… : 0820331334
        
        
        Médical : 
        
        Généralistes : 
        Cabinet médical Docteurs Raoux et Soussana à Lédignan : 04 66 83 42 42
        Docteur Bal à Lézan : 04 66 83 00 08
        Docteur Moulayes à Lézan : 04 66 83 06 22

        
        Dentistes: 
        Cabinet médical à Lédignan (Docteurs Deleuze, Raberin, Roustan) : 04 66 60 99 68
        Cabinet médical à Lézan (Docteurs Camier-Feljas, Landelle, Abric) : 04 66 83 40 39
        Docteur Bouziges à Ribaute les Tavernes : 04 66 83 08 25

        
        Osthéopathes :
        Francis Humbert à Lédignan : 06 20 20 02 59 
        Thibault Dubois à Lézan : 06 15 71 60 77

        
        Infirmières: 
        Lédignan
        Isabelle Veleine-Grinand 04 66 83 42 54
        Karine Alves 06 60 96 44 93
        Nathalie Busnel 04 34 13 93 09
        Pascale Folcher 06 20 64 47 41
        
        Lézan
        Delphine Malafosse 04 66 83 07 61
        Elisabeth Raï Muscio 06 49 93 32 71
        Estelle Gabarel 06 49 93 32 71
        Murielle Bazouin 04 66 83 07 61
        Véronique Vincent 04 66 83 07 61
        
        Ribaute les Tavernes
        Anne Pontier Tixedor 06 98 83 49 87
        Caroline Perrier Vernet 06 81 60 86 97
        Charlène Choisnet 04 66 83 86 18
        Frederic Calmette 06 21 94 31 69


        Kinésithérapeutes :
        Laurence Dellot à Lédignan : 06 12 90 71 85
        Olivier Costecalde à Lédignan : 04 66 54 03 36
        André Gautier et Nela Jachimowicz Markiewicz à Lézan : 04 66 83 00 01
        Pascal Demarche à Ribaute les Tavernes : 04 66 83 82 41


        Pharmacies :
        Pharmacie Bertrand à Lédignan : 04 66 83 24 28
        Pharmacie des Vignes Gard-Cévennes à Lézan : 04 66 83 00 65

        
        Bien-être :
        Sophromag: 06 70 24 26 27
        Réflexion plantaire: 06 58 26 74 00
        `
    },
    {
        id: 18,
        path: '/vie-quotidienne/bulletin-municipal-le-cardesien',
        name: 'Bulletin municipal le Cardésien',
        content: ``
    },
    {
        id: 19,
        path: '/territoire/plan-local-durbanisme/procedure',
        name: 'Procédure',
        content: ``
    },
    {
        id: 20,
        path: '/territoire/plan-local-durbanisme/rapport-de-presentation',
        name: 'Rapport de présentation',
        content: ``
    },
    {
        id:21,
        path: '/territoire/plan-local-durbanisme/padd',
        name: 'PADD',
        content: ``
    },
    {
        id: 22,
        path: '/territoire/plan-local-durbanisme/reglement',
        name: 'Règlement',
        content: ``
    },
    {
        id: 23,
        path: '/territoire/plan-local-durbanisme/annexes',
        name: 'Annexes',
        content: ``
    },
    {
        id: 24,
        path: '/territoire/plan-local-durbanisme/orientations-damenagement',
        name: 'Orientations d\'aménagement',
        content: ``
    },
    {
        id: 25,
        path: '/territoire/prevention-des-risques/les-bruits',
        name: 'Les bruits',
        content: `Arrêté préfectoral n°2008-193-7, vise à réglementer les bruits de voisinage.​
        
        Article 1
        Il est interdit, de jour comme de nuit, d’émettre, sans nécessité ou par défaut de précautions, des bruits de nature à porter atteinte à la tranquillité du voisinage ou à la santé de l’homme, par leur durée, leur repétition ou leur intensité. 
        
        Article 7 
        Il réglemente les bruits générés à l’intérieur des habitations, de leurs dépendances et de leurs abords tels que:
        - les cris d’animaux;
        - les appareils de diffusion de son et de musique;
        - les travaux de jardinage ou de bricolage;
        - les appareils électroménagers;
        - les comportements bruyants pratiqués dans des lieux inadaptés;
        - les pétards ou pièces d’artifice;
        - les équipements fixes, intérieurs ou extérieurs, individuels ou collectifs, tels que chauffage, climatisation, ventilation mécanique (bouches et extracteurs), systèmes d’évacuation d’eaux usées, ascenseurs et monte-charge, vide-ordures, portes motorisées, surpresseurs, systèmes de filtration des piscines, syxtème d’arrosage automatique, alarmes.
         
        Article 8
        Les occupants et les utilisateurs des locaux privés, d’habitations, de leurs dépendances et de leurs abords doivent prendre toutes précautions pour éviter que le voisinage soit gêné par les bruits émanant de leurs activités, de leurs animaux domestiques, des appareils ou machines qu’ils utilisent ou des travaux qu’ils effectuent. A cet effet, ils doivent adapter leur comportement à l’environnement et à l’état des locaux en ce qui concerne l’isolation phonique et notamment:
        - régler le volume sonore de leurs appareils producteurs de sons, de façon à ce que leur fonctionnement ne soit pas perceptible dans les logements des voisins;
        - veiller à ce que les bruits de pas, les chutes d’objet, les déplacements de mobiliers ne puissent être perçus par les voisins, ou à réduire autant que possible leur impact, notamment en installant des dispositifs isolants au point de contact des meubles, ou en faisant placer des revêtements isolants sur le sol des planchers des étages;
        veiller à ce que leur comportement et celui de leurs animaux ne soit pas une source de trouble de voisinage;
        - utiliser si nécessaire les appareils les moins bruyants disponibles sur le marché, les entretenir pour maintenir leur performance initiale, et éviter d’utiliser des appareils électroménagers bruyants entre 22h et 7 h;
        - ne pas utiliser, pour des travaux de bricolage et de jardinage, des appareils à moteur en dehors des horaires suivants:
        - - de 8h30 à 19h30, les jours ouvrés;
        - - de 9h à 12h et de 15h à 19h, les samedis;
        - - de 10h à 12h, les dimanches et jours fériés.`
    },
    {
        id: 26,
        path: '/territoire/prevention-des-risques/les-animaux',
        name: 'Les animaux',
        content: `La divagation des animaux
        Tout propriétaire d’animal en divagation est passible d’une amende par arrêté municipal.
        
        Il est interdit de laisser divaguer un animal domestique (article L. 211-19-1 du Code rural). Hormis l’hypothèse d’une action de chasse ou de la garde ou de la protection du troupeau, le chien est considéré comme en état de divagation lorsqu’il n’est plus sous la surveillance effective de son maître, se trouve hors de portée de voix de celui-ci ou de tout instrument sonore permettant son rappel ou qui est éloigné de son propriétaire d’une distance dépassant cent mètres (article L. 211-23 du Code rural).
        
        Le chat est considéré comme en état de divagation lorsqu’il est trouvé à plus de mille mètres du domicile de son maître et qu’il n’est pas sous la surveillance immédiate de celui-ci. De même, le chat non identifié trouvé à plus de deux cent mètres des habitations ou dont le propriétaire n’est pas connu et qui est saisi sur la voie publique ou sur la propriété d’autrui est considéré comme en état de divagation(article L. 211-23 du Code rural).
        
        Lorsqu’un animal est trouvé en divagation sur la voie publique, il doit être conduit à la fourrière animale du lieu où il a été trouvé. La fourrière doit alors prévenir son propriétaire, qui dispose d’un délai de 8 jours ouvrés pour venir le chercher (article L. 211-24 du Code rural).
        
        Voici le numéro de la fourrière, la SACPA : 04 66 72 82 86`
    },
    {
        id: 27,
        path: '/territoire/eau-assainissement',
        name: 'Eau et assainissement',
        content: `Assainissement collectif
        L’assainissement est dit « collectif » lorsque l’habitation est raccordée à un réseau communal d’assainissement.
         
        Assainissement non collectif
        Toutes les habitations qui ne sont pas desservies par un réseau de collecte des eaux usées (égouts) doivent être équipées d’une installation autonome dite « assainissement non collectif » pour traiter individuellement leurs eaux usées domestiques. 
        
        Information sur la qualité de l’eau potable
         
        Personnes en situation précaire
        
        Les personnes en situation précaire peuvent demander à bénéficier d’une aide exceptionnelle lorsqu’elles ne peuvent plus faire face au règlement de leurs factures d’eau.`
    },
    {
        id: 28,
        path: '/territoire/patrimoine',
        name: 'Patrimoine',
        content: [
            {
                id: 1,
                name: 'Le château',
                content: 'Le château, avec son magnifique parc de 7 hectares, est intimement lié à l’histoire de Cardet. Sa partie ancienne, comprenant des caves voutées, remonterait au début du XIIIè siècle. Des aménagements et des embellissements intervinrent à plusieurs reprises au cours des siècles. C’est vers 1710 que le seigneur de Cardet à l’époque, M de Bouzanquet, lui donna son allure et aménagea les bosquets. De nombreux héritiers lui succédèrent.',
                image: chateau,
            },
            {
                id: 2,
                name: 'Le Temple',
                content: 'Le temple de Cardet est un temple protestant du XVIIe siècle. C’est l’un des très rares temples rescapés de la révocation de l’Édit de Nantes en 1685 parce que transformé en église catholique jusqu’à la Révolution. Histoire du temple',
                image: temple,
            },
            {
                id: 3,
                name: 'Le bâtiment de l\'ancienne poste',
                content: `Ce bâtiment a été construit en 1911 pour accueillir le bureau de poste. Il était composé d’un bureau de poste et d’un logement de fonction avec une petite cour. 
                
                Aujourd’hui, comme l’agence postale communale se trouve à l’accueil de la mairie, le bâtiment après rénovation se compose au rez-de-chaussée de la bibliothèque municipale (depuis 2016) et à l’étage des bureaux du service technique et d’une grande salle que la municipalité prête au cercle de généalogie Gard-Lozère.`,
                image: anciennePoste,
            },
            {
                id: 4,
                name: 'La mairie & les écoles',
                content: `La mairie a été construite avec les écoles dans les années 1880-1882. A l’époque, il y avait le bâtiment de la mairie et à l’étage un logement de fonction. 
                
                En 2015, de gros travaux de rénovation et d’aménagement de la mairie mais aussi des écoles ont eu lieu en grande partie pour éviter le risque inondation. Depuis, l’accueil de la mairie et l’agence postale communale se trouvent au rez-de-chaussée du bâtiment. Les bureaux du maire et des secrétaires, ainsi que la grande salle des mariages et des conseils municipaux se situent à l’étage. 
                
                Concernant les écoles, les classes se situaient des deux côtés du bâtiment de la mairie, ainsi que la cour derrière avec deux préaux. Dans les années 90, un nouveau bâtiment comprenant une nouvelle salle a été construit derrière l’un des deux préaux, préau qui est devenue une petite salle de détente avec des livres et des jeux. En même temps que les travaux de la mairie en 2015, des aménagements ont été faits aux écoles comme la création d’une salle de motricité, d’un dortoir et de nouveaux sanitaires, ainsi que des aménagements contre le risque inondation.`,
                image: mairieEcoles,
            },
            {
                id: 5,
                name: 'Les anciennes douches publiques',
                content: `Image du passé, vieux souvenir d’antan, le petit bâtiment des bains-douches fait partie du patrimoine local pour ses habitants. Ce bâtiment public aux dimensions modestes, indépendant des autres bâtiments municipaux, excentré du reste du village, occupant un angle au bout de deux rues est un repère, comme un point d’ancrage dans la commune. 
                
                À travers les années, il témoigne d’une avancée sociale qu’ont connue les anciennes générations. Auparavant, y étaient annexés des WC publics, bien utiles à une époque pas si lointaine, où le tout-à-l’égout ne desservait pas encore le village. Au fil des générations, le bâtiment s’est même vu transformé occasionnellement en discothèque. 
                
                Malgré les inondations de 1958 et 2002, le petit local a su résister contre vents et marées. Encore bien conservé, l’endroit a récemment fait office de bibliothèque. Il est aujourd’hui destiné aux associations cardésiennes.`,
                image: douchesPubliques,
            },
            {
                id: 6,
                name: 'L\'ancienne école des mas',
                content: `Elle fut construite en 1910-1911 en contrebas du quartier du Mas Julian. Elle était constituée d’une grande classe, d’un préau et d’une cour. Il y avait également un logement de fonction. Après avoir accueilli pendant de nombreuses années des élèves, elle a dû fermer. 
                
                Dans les années 80, un jardin d’enfants est crée dans les locaux. Le bâtiment a ensuite été à l’abandon pendant de nombreuses années pour diverses raisons. 
                
                En 2021, des travaux ont été menés pour réhabiliter ce bâtiment qui fait partie du patrimoine de la commune. Aujourd'hui, on y trouve plusieurs espaces, dont notamment une Maison d’Assistantes Maternelles (MAM).`,
                image: ecoleDesMas,
            },
            {
                id: 7,
                name: 'La cave coopérative',
                content: `C’est à la fin du XIXème siècle qu’en vertu du vieil adage L’union fait la force se développa et se généralisa la création des coopératives agricoles. En 1924, sous la dynamique impulsion de Jacques de Chapel et Gaston Maurin, la Coopérative des Vignerons de Cardet était créée. La construction du bâtiment était donc entreprise. Bâtiment classique à l’époque avec ses cuves en béton d’une capacité totale de 15000 hectolitres disposées en fer à cheval et le logement du gérant incorporé au bâtiment lui-même, afin de faciliter la surveillance de la cave une fois la récolte rentrée.
        
                En 1930, un agrandissement portait la capacité de la cave à 24000 hectolitres, puis une trentaine d’années plus tard à 58000 hectolitres avec l’installation de matériels plus modernes et automatisés.
                De nos jours, la cave compte en plus un caveau qui a été construit devant le plus ancien bâtiment.`,
                image: caveCooperative,
            }
        ], 
    },
    {
        id: 29,
        path: '/territoire/les-artistes',
        name: 'Les artistes',
        content: [
            {
                id: 1,
                name: 'Artiste Peintre Margaret Early',
                content: `Margaret EARLY est australienne et vit en France depuis 20 ans. Aimant l’Art du Moyen-Age, elle a fait les illustrations de six livres d’Art pour enfants. 
                Elle travaille, actuellement, sur les cités d’Europe au Moyen-Age.`,
            },
            {
                id: 2,
                name: 'Artistes Céramique, Peinture, Verre, Vitrail  Sylvie & Thierry Gilhodez',
                content: `Horaires : Du Lundi au Vendredi de 10H à 12H et de 14H à 18H, les samedis et dimanches sur rendez-vous.
                Atelier Poterie/ Céramique : tous les mardis après-midi de 14h à 17h à l’atelier.
                Atelier Guitare : tous les mercredis de 15h à 16h. Salle des Bains/Douches.
                Orchestre de Jazz : répétition tous les samedis de 10h à 12h. Salle des Bains/Douches.`,
                link: 'http://www.idverre.net/gilhodez',
                address: '870, Route des Cévennes.',
                mail: 'gilhodezsylvie@gmail.com et thierry.gilhodez@caramail.com',
                tel: '04.66.83.85.18  /  06 59 45 58 03', 
            },
            {
                id: 3,
                name: 'Atelier Corinne Jeanjacques',
                content: `Peintre /Créatrice sur porcelaine`,
                address: '4 lot les jardins de Cardet.',
                tel: '04.66.34.53.90 / 06.62.65.56.80'
            },
            {
                id: 4,
                name: 'Antonius Driessens',
                content: `artiste plasticien`,
                link: 'http://www.antoniusdriessens.com',
                address: '21 rue du temple',
                tel: '09 50 88 50 21 / 06 52 42 41 90 '
            },
            
        ], 
    },
    {
        id: 30,
        path: '/territoire/un-peu-dhistoire',
        name: 'Un peu d\'histoire',
        content: `Cardet la Plaine Viticole

        Avant le XIV siècle, le village était situé sur les hauteurs où sont les hameaux appelés Mas De Cardet. Le village s’appelait Saint-Saturnin de Coiran.
        Son nom actuel proviendrait de son activité de culture du chanvre qui était ensuite cardé et du geste de cardage qui en résultait.
        
        Au XVII siècle, les plantations de muriers et l’élevage du vers à soie furent une des principales ressources de la population et ceci jusqu’au début du XIX siècle. La plupart des bâtisses et constructions de cette époque étaient destinées à l’usage des magnaneries. Ensuite, peu à peu, la culture de la vigne se développa et prit place dans toute la plaine.
        
        Le village prit naissance à son emplacement au bord du Gardon, lorsqu’une verrerie fut construite sur l’emplacement du château actuel. Les années 1880-1882 furent marquées par des constructions publiques et utilitaires telles que la construction des écoles et de la Mairie qui marquent une date dans l’agrandissement du village.
        
        Le bâtiment dit du bureau de poste, construit en 1911, héberge aujourd’hui la bibliothèque. L’école dite école du Mas Julian fut construite aussi à cette époque, 1910-1911. La cave coopérative a vu le jour en 1924.
        
        Le temple de Cardet est un temple protestant du XVII siècle. C’est l’un des très rares temples rescapés de la révocation de l’Edit de Nantes en 1685 parce que transformé en église catholique jusqu’a la Révolution. Il y a été célébré le mariage des parents de Jean Cavalier (célèbre chef Camisard) le 30 Juillet 1678 et c’est certainement là qu’il fut baptisé le 7 octobre 1681;
        
        Aujourd’hui la population de la commune de Cardet est, au dernier recensement, de 905 habitants. Elle profite d’une activité économique à travers ses commerces, son artisanat, ses restaurants, son offre touristique, et bien sur l’agriculture et en particulier la viticulture.
        
        Quelques précisions et chiffres :
        Adresse: 1 place de la mairie 30350 Cardet.
        Maire : Fabien Cruveiller.
        Population : 905 habitants.
        Superficie : 8,29 Km2.
        Altitude : 166 m.
        Nom des Habitants : Cardésiennes, Cardésiens.
        
        
        `,
        filesTitle: 'Histoire et description du logo de Cardet',
        files: [
            {
                id: 1,
                name: 'Histoire du logo de Cardet',
                image: histoireLogo,
            },
            {
                id: 2,
                name: 'Histoire du logo de Cardet',
                image: histoireLogo2,
            },
        ],
    },
    {
        id: 31,
        path: '/tourisme/gites-chambres-dhotes',
        name: 'Gîtes & Chambres d\'hôtes',
        content: [
            {
                id: 1,
                name: 'Gîte & Maison d\'hôtes L\'Orangerie',
                content: `Laissez votre quotidien derrière vous et profitez de votre temps libre. Avec son charme unique et historique, l’Orangerie de Cardet est le lieu idéal pour passer des vacances paradisiaques dans l’une des plus belles régions de France.`,
                address: '4 Chemin des Bugadières.',
                tel: '+33 (0)6 36 17 90 99',
                link: 'https://www.orangeriedecardet.com/',
                image: orangerie,
            },
            {
                id: 2,
                name: 'Gîte "La Bastide"',
                content: `Situé à 35km de NIMES, 15 km d’Alès , 10 km d’Anduze ainsi qu’à 20mn des Cévennes et à 45mn de la mer, c’est un endroit idéal pour se reposer et découvrir le charme de la région.`,
                image: laBastide,
                link: 'https://gitecardet.wixsite.com/gite',
            },
            {
                id: 3,
                name: 'Gîte indépendant dans mas cévenol',
                content: `Calme et authenticité pour cette tour aménagée et meublée avec charme sur 2 niveaux avec son solarium privé sur le toit.`,
                address: 'Mas de l’Eglise.',
                tel: '04 66 83 01 99', 
                image: masCevenol,
            },
            {
                id: 4,
                name: 'Maison d\'hôtes du Mas Julian',
                content: `Occupant le site de l’ancienne propriété familiale, notre maison d’hôtes bénéficie d’une vue exceptionnelle sur la ligne bleue des Cévennes. 

                Enchâssée dans un écrin de verdure,  elle a repris vie pour vous accueillir. Vous découvrirez les nombreuses variétés qui habitent le jardin d’agrément depuis bien des années.
                
                Les soirs d’été, le bruissement des bambous apportera une note bucolique à votre séjour estival.`,
                image: masJulian,
            },
            {
                id: 4,
                name: 'Gîte au mas Font Granada',
                content: `Pour les amoureux de la nature, des chevaux et de l’authenticité des lieux au pied des monts cevenol et à 1 h des premières plages, Gîte pour 4 personnes A la semaine, au week end et à la nuitee.`,
                image: fontGranada,
            },
            {
                id: 5,
                name: 'Gîte "Les Hirondelles"',
                content: `Converti en 2016 d’une ancienne grange qui a été utilisée pour produire du vin, le gîte est indépendant et parfait pour 5 personnes.
                Dans une enceinte fleurie et une belle vue sur les collines boisées et les vignobles, cette maison d’environ 65 m² dispose de 2 chambres, dont une en mezzanine, d’une cuisine indépendante bien équipée, d’une salle d’eau et du chauffage / climatisation, d’une salle de séjour / salle à manger.`,
                address: "Les Arnasseaux",
                tel: ' 0044 1666 880 297 ou 0044 7805 952 802',
                mail: 'hookshouse@hotmail.co.uk',
                image: lesHirondelles,
                link: 'http://hookshousepottery.co.uk/gite/',
            },
            
        ],
    },
    {
        id: 32,
        path: '/tourisme/campings',
        name: 'Campings',
        content: [
            {
                id: 1,
                name: 'Camping du Chercheur d\'Or',
                content: `Un camping familial en bord de rivière.
                Nous sommes situé dans un cadre de verdure de 10ha au bord du Gardon d’Anduze, à 35 Km de Nîmes et 45 km de Montpellier.`,
                address: '615 Chemin du Libac',
                tel: '04.66.83.82.44 ou 06.48.30.06.43',
                link: 'https://www.camping-chercheur-dor.fr/',
                mail: 'camping-chercheur-dor@orange.fr',
                image: chercheurOr,
            },
            {
                id: 2,
                name: 'Camping Beau Rivage',
                content: `Le camping Beau Rivage est situé au pied des Cévennes, dans l’authentique village de Cardet, au milieu des vignes vallonnées. Notre camping familial confortable, est situé directement au bord de la rivière le Gardon.`,
                image: beauRivage,
                address: '22 chemin du Bosquet',
                tel: '04 66 83 02 48',
                mail: 'mail@camping-beau-rivage.net',
                link: 'https://camping-beau-rivage.net/fr/home-fr/',
            }
        ],
    },
    {
        id: 33,
        path: '/actualite',
        name: 'Actualité',
    },
    {
        id: 34,
        path: '/agenda',
        name: 'Evénements à venir',
    },
];

export default pages;